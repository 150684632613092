//@import "assets/scss/paper-kit";
@import 'assets/scss/netfone/netfone';

html,
body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
}

body {
  overflow-x: hidden;
}

.modal.fade .modal-dialog {
  transform: none;
}

.modal-footer .left-side,
.modal-footer .right-side {
  margin: 0;
  width: 50%;
}

@include px_768 {
  .netfone-container {
    max-width: 720px;
  }
}

@include px_1200 {
  .netfone-container {
    max-width: 1100px;
  }
}

@font-face {
  font-family: Anton;
  src: url('/assets/fonts/Anton-Regular.woff2') format('woff2');
  font-display: swap;
}

// https://transfonter.org/
// 100
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Thin.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Thin.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-ThinItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-ThinItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
//200
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-ExtraLight.ttf') format('v');
  src: url('./woff/BarlowCondensed-ExtraLight.woff') format('woff');
  src: url('./woff2/BarlowCondensed-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-ExtraLightItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-ExtraLightItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
//300
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Light.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Light.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-LightItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-LightItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
//400
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Regular.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Regular.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Italic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Italic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
//500
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Medium.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Medium.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-MediumItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-MediumItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
//600
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-SemiBold.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-SemiBold.woff') format('woff');
  src: url('./woff2/BarlowCondensed-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-SemiBoldItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-SemiBoldItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}
//700
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Bold.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Bold.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-BoldItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-BoldItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
//800
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-ExtraBold.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-ExtraBold.woff') format('woff');
  src: url('./woff2/BarlowCondensed-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-ExtraBoldItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-ExtraBoldItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}
//900
@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-Black.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-Black.woff') format('woff');
  src: url('./woff2/BarlowCondensed-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('./ttf/BarlowCondensed-BlackItalic.ttf') format('ttf');
  src: url('./woff/BarlowCondensed-BlackItalic.woff') format('woff');
  src: url('./woff2/BarlowCondensed-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

@import 'netfone_variables';

.ntfc-modal-close {
  font-size: 20px;
  color: black;
  outline: none !important;
  border: 1px solid black;
  border-radius: 50%;
  font-weight: 600;
  height: 30px;
  width: 30px;
  line-height: 27px;

  &:hover {
    color: $netfone-secondary;
    border-color: $netfone-secondary;
  }
}

.modal-backdrop.fade {
  transition: unset;
}

//Own Variables
//$netfone-color-light : #ffae0f !default;
//$netfone-color-dark: #ff920a !default;
//$netfone-color-light: #fcb525 !default;
//$netfone-filter-grey: #4e5859 !default;
//
//$light-orange: #FFC032;
//$normal-orange: #FFB612;
//$dark-orange: #FEA800;
//$white: #FFFFFF;
//$black: #000000;
//$grey: #4F5757;

$netfone-primary: #ffb612;
$netfone-secondary: #f59130;

$netfone-state-valid: #89c349;
$netfone-state-error: #f75c64;

$netfone-text-primary: #4d5357;
$netfone-text-secondary: #2f3333;

$netfone-new-text-primary: #4d5357;

$netfone-background-primary: #ffffff;
$netfone-background-secondary: #c2c2c2;
$netfone-background-grey: #eeeeee;

$netfone-table-border-grey: #eeeeee;
$netfone-table-bg-grey: #c2c2c2;

$netfone-new-background-grey: #e1e2e2;

$netfone-black: #000000;
$netfone-white: #ffffff;
$netfone-light-orange: #ffc032;
$netfone-dark-orange: #fea800;
$netfone-grey: #eeeeee;
$netfone-dark-grey: #c4c4c4;
$netfone-davys-grey: #4c5257;

$netfone-new-orange: #ffc300;
$netfone-new-dark-orange: #ffc40a;
$netfone-new-light-orange: #fff5df;
$netfone-new-gray: #8c9092;
$netfone-new-light-gray: #e1e2e2;
$netfone-new-tan: #fff5df;
$netfone-new-tan-2: #ffe8a2;
$netfone-new-tan-3: #ffe17f;

$netfone-new-background-light-orange: #fff5df;
$netfone-new-input-background: #e1e2e2;

$facebook: #3b5998;
$facebook-hoover: #213a6f;

$netfone-red: #e61c37;
$netfone-tomato-red: #fa5555;
$red: #ff0000;
$dark-chocolate: #450101;

$netfone-turquoise: #009892;
$netfone-purple: #420049;

$checkbox-padding: 39px;

@function calcLeft($baseLeft, $baseWindowWidth) {
  @return calc(((100vw - #{$baseWindowWidth}) / 2) + #{$baseLeft});
}

@mixin px_300 {
  @media screen and (min-width: 300px) {
    @content;
  }
}

@mixin px_576 {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin px_768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin px_960 {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin px_1200 {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

// IE 10+
@mixin ie-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// IE Edge 10+ {
@mixin ie-edge-only {
  @supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
    @content;
  }
}

// Safari 10.1+
@mixin safari-only {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      @content;
    }
  }
}

// Firefox 29+
//noinspection CssInvalidMediaFeature
@mixin firefox-only {
  @media all and (min--moz-device-pixel-ratio: 0) and (min-resolution: 3e1dpcm) {
    @content;
  }
}

// https://transfonter.org/
// 100
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Thin.ttf') format('ttf');
  src: url('./woff/Barlow-Thin.woff') format('woff');
  src: url('./woff2/Barlow-Thin.woff2') format('woff2');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-ThinItalic.ttf') format('ttf');
  src: url('./woff/Barlow-ThinItalic.woff') format('woff');
  src: url('./woff2/Barlow-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}
//200
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-ExtraLight.ttf') format('ttf');
  src: url('./woff/Barlow-ExtraLight.woff') format('woff');
  src: url('./woff2/Barlow-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-ExtraLightItalic.ttf') format('ttf');
  src: url('./woff/Barlow-ExtraLightItalic.woff') format('woff');
  src: url('./woff2/Barlow-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}
//300
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Light.ttf') format('ttf');
  src: url('./woff/Barlow-Light.woff') format('woff');
  src: url('./woff2/Barlow-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-LightItalic.ttf') format('ttf');
  src: url('./woff/Barlow-LightItalic.woff') format('woff');
  src: url('./woff2/Barlow-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
//400
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Regular.ttf') format('ttf');
  src: url('./woff/Barlow-Regular.woff') format('woff');
  src: url('./woff2/Barlow-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Italic.ttf') format('ttf');
  src: url('./woff/Barlow-Italic.woff') format('woff');
  src: url('./woff2/Barlow-Italic.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
//500
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Medium.ttf') format('ttf');
  src: url('./woff/Barlow-Medium.woff') format('woff');
  src: url('./woff2/Barlow-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-MediumItalic.ttf') format('ttf');
  src: url('./woff/Barlow-MediumItalic.woff') format('woff');
  src: url('./woff2/Barlow-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
//600
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-SemiBold.ttf') format('ttf');
  src: url('./woff/Barlow-SemiBold.woff') format('woff');
  src: url('./woff2/Barlow-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-SemiBoldItalic.ttf') format('ttf');
  src: url('./woff/Barlow-SemiBoldItalic.woff') format('woff');
  src: url('./woff2/Barlow-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}
//700
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Bold.ttf') format('ttf');
  src: url('./woff/Barlow-Bold.woff') format('woff');
  src: url('./woff2/Barlow-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-BoldItalic.ttf') format('ttf');
  src: url('./woff/Barlow-BoldItalic.woff') format('woff');
  src: url('./woff2/Barlow-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
//800
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-ExtraBold.ttf') format('ttf');
  src: url('./woff/Barlow-ExtraBold.woff') format('woff');
  src: url('./woff2/Barlow-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-ExtraBoldItalic.ttf') format('ttf');
  src: url('./woff/Barlow-ExtraBoldItalic.woff') format('woff');
  src: url('./woff2/Barlow-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}
//900
@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-Black.ttf') format('ttf');
  src: url('./woff/Barlow-Black.woff') format('woff');
  src: url('./woff2/Barlow-Black.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./ttf/Barlow-BlackItalic.ttf') format('ttf');
  src: url('./woff/Barlow-BlackItalic.woff') format('woff');
  src: url('./woff2/Barlow-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

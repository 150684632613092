// https://transfonter.org/
// 100
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Thin.ttf') format('ttf');
  src: url('./woff/Montserrat-Thin.woff') format('woff');
  src: url('./woff2/Montserrat-Thin.woff2') format('woff2');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-ThinItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-ThinItalic.woff') format('woff');
  src: url('./woff2/Montserrat-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}
//200
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-ExtraLight.ttf') format('ttf');
  src: url('./woff/Montserrat-ExtraLight.woff') format('woff');
  src: url('./woff2/Montserrat-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-ExtraLightItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-ExtraLightItalic.woff') format('woff');
  src: url('./woff2/Montserrat-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}
//300
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Light.ttf') format('ttf');
  src: url('./woff/Montserrat-Light.woff') format('woff');
  src: url('./woff2/Montserrat-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-LightItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-LightItalic.woff') format('woff');
  src: url('./woff2/Montserrat-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
//400
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Regular.ttf') format('ttf');
  src: url('./woff/Montserrat-Regular.woff') format('woff');
  src: url('./woff2/Montserrat-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Italic.ttf') format('ttf');
  src: url('./woff/Montserrat-Italic.woff') format('woff');
  src: url('./woff2/Montserrat-Italic.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
//500
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Medium.ttf') format('ttf');
  src: url('./woff/Montserrat-Medium.woff') format('woff');
  src: url('./woff2/Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-MediumItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-MediumItalic.woff') format('woff');
  src: url('./woff2/Montserrat-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
//600
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-SemiBold.ttf') format('ttf');
  src: url('./woff/Montserrat-SemiBold.woff') format('woff');
  src: url('./woff2/Montserrat-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-SemiBoldItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-SemiBoldItalic.woff') format('woff');
  src: url('./woff2/Montserrat-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}
//700
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Bold.ttf') format('ttf');
  src: url('./woff/Montserrat-Bold.woff') format('woff');
  src: url('./woff2/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-BoldItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-BoldItalic.woff') format('woff');
  src: url('./woff2/Montserrat-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
//800
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-ExtraBold.ttf') format('ttf');
  src: url('./woff/Montserrat-ExtraBold.woff') format('woff');
  src: url('./woff2/Montserrat-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-ExtraBoldItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-ExtraBoldItalic.woff') format('woff');
  src: url('./woff2/Montserrat-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}
//900
@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-Black.ttf') format('ttf');
  src: url('./woff/Montserrat-Black.woff') format('woff');
  src: url('./woff2/Montserrat-Black.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./ttf/Montserrat-BlackItalic.ttf') format('ttf');
  src: url('./woff/Montserrat-BlackItalic.woff') format('woff');
  src: url('./woff2/Montserrat-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

@import 'netfone_variables';

.netfone-button {
  outline: none !important;
  height: 40px;

  padding-left: 20px;
  padding-right: 20px;

  background: none;
  border-radius: 10px;
  border: 1px solid $netfone-primary;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  font-family: 'Open Sans', sans-serif;

  &.primary {
    color: $netfone-white;
    background-color: $netfone-primary;

    &:hover {
      color: $netfone-white;
      background-color: $netfone-light-orange;
    }

    &:active {
      color: $netfone-white;
      background-color: $netfone-dark-orange;
    }

    &:disabled {
      color: $netfone-white;
      border: 1px solid $netfone-background-secondary;
      background-color: $netfone-background-secondary;
    }
  }

  &.secondary {
    color: $netfone-primary;
    background-color: $netfone-white;

    &:hover {
      color: $netfone-white;
      background-color: $netfone-light-orange;
    }

    &:active {
      color: $netfone-white;
      background-color: $netfone-dark-orange;
    }

    &:disabled {
      color: $netfone-white;
      border: 1px solid $netfone-background-secondary;
      background-color: $netfone-background-secondary;
    }
  }

  &.red {
    color: $netfone-white;
    background-color: $netfone-red;
    border-color: $netfone-red;

    &:hover {
      color: $netfone-white;
      background-color: lighten($netfone-red, 10%);
    }

    &:active {
      color: $netfone-white;
      background-color: $netfone-red;
    }

    &:disabled {
      color: $netfone-white;
      border: 1px solid $netfone-background-secondary;
      background-color: $netfone-background-secondary;
    }
  }

  &.tertiary {
    color: $netfone-white;
    background-color: $netfone-new-text-primary;

    &:hover {
      background-color: lighten($netfone-new-text-primary, 0.3);
    }
  }

  &.danger {
    color: $netfone-state-error;
    background-color: $netfone-white;
    border: 1px solid $netfone-state-error;

    &:hover {
      color: $netfone-white;
      background-color: $netfone-state-error;
    }
  }
}

.new-netfone-button {
  outline: none !important;
  height: 40px;

  padding-left: 20px;
  padding-right: 20px;

  background: none;
  border-radius: 10px;
  border: none;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  text-decoration: none;

  &.primary {
    color: $netfone-white;
    background-color: $netfone-new-text-primary;

    &:hover {
      background-color: lighten($netfone-new-text-primary, 10%);
    }
  }

  &.secondary {
    color: $netfone-new-text-primary;
    background-color: $netfone-new-orange;

    &.black {
      color: $netfone-black;
    }

    &.secondary-white {
      color: $netfone-white;
    }

    &:hover {
      background-color: lighten($netfone-new-orange, 10%);
    }

    &:disabled {
      color: $netfone-white;
      border: 1px solid $netfone-background-secondary;
      background-color: $netfone-background-secondary;
      cursor: not-allowed;
    }
  }

  &.white {
    background-color: $netfone-white;
    color: $netfone-new-text-primary;

    &:hover {
      background-color: lighten($netfone-new-text-primary, 10%);
      color: $netfone-white;
    }
  }

  &.white-with-black {
    background-color: $netfone-white;
    color: $netfone-black;

    &:hover {
      background-color: lighten($netfone-black, 10%);
      color: $netfone-white;
      text-decoration: none;
    }
  }

  &.black-with-white {
    background-color: $netfone-black;
    color: $netfone-white;

    &:hover {
      background-color: lighten($netfone-black, 10%);
    }
  }

  &.danger {
    color: $netfone-white;
    background-color: $netfone-state-error;
    border: 1px solid $netfone-state-error;

    &:hover {
      color: $netfone-state-error;
      background-color: $netfone-white;
    }
    &:disabled {
      color: $netfone-white;
      border: 1px solid $netfone-background-secondary;
      background-color: $netfone-background-secondary;
      cursor: not-allowed;
    }
  }

  &.grey {
    color: $netfone-white;
    background-color: $netfone-background-secondary;

    &:hover {
      background-color: lighten($netfone-background-secondary, 10%);
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

@include px_768 {
  .new-netfone-button {
    font-size: 18px;
    padding-right: 35px;
    padding-left: 35px;
    height: 50px;
  }
}

@import 'netfone_variables';
@import 'buttons';
@import 'modal';
@import 'swiper';

.netfone-link {
  color: $netfone-dark-orange;
  cursor: pointer;
}

.netfone-link:hover,
.netfone-link:focus {
  color: $netfone-light-orange !important;
  outline: unset;
  text-underline: $netfone-light-orange;
}

.mouse-pointer {
  cursor: pointer;
}

.text-align-end {
  text-align: end;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-space-around {
  justify-content: space-around;
}
.flex-justify-space-between {
  justify-content: space-between;
}

.contact-input {
  margin-bottom: 20px;
}

.pdf-download-main {
  margin-top: 20px;
}

.pdf-download-card {
  margin: 20px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.row {
  margin: 0;
}

.ntfc-default-padding {
  padding: 20px;
}

.word-break {
  word-break: break-all;
}

.loader-padding-bottom {
  padding-bottom: calc((100vh - 73px) / 4);
}

.cursor-pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.toggle-button {
  border: unset;
  background-color: unset;

  &:focus {
    outline: none !important;
  }

  img {
    cursor: pointer;

    &:focus {
      outline: none !important;
    }
  }
}

.ntfc-drawer-open {
  transform: rotate(90deg);
  transition-duration: 0.5s;
}

.ntfc-drawer-closed {
  transform: rotate(0deg);
  transition-duration: 0.5s;
}

.ntfc-dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 32.5px;
  text-align: start;
}

.ntfc-dropdown-title,
.ntfc-primary-title-black {
  margin-left: 21.5px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: $netfone-text-secondary;
}

@include px_1200 {
  .ntfc-dropdown-title {
    font-size: 20px;
  }
}

.ntfc-dropdown-secondary-title,
.ntfc-secondary-title-black {
  font-style: normal;
  margin-left: 21.5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #2f3333;
}

@include px_1200 {
  .ntfc-dropdown-secondary-title,
  .ntfc-secondary-title-black {
    font-size: 20px;
  }
}

.ntfc-secondary-drawer-wrapper {
  margin-left: 65px;
  margin-top: 18px;
}

.ntfc-tnc-main-grey-line,
.ntfc-tnc-secondary-grey-line {
  height: 0;
  border: 1px solid #4f5757;
}

.ntfc-tnc-main-grey-line {
  margin: 40px 32.5px 35px;
}

.ntfc-tnc-secondary-grey-line {
  margin-top: 24px;
  margin-bottom: 28px;
}

.ntfc-tnc-download-document-wrapper {
  display: flex;
  flex-direction: column;

  span,
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #4f5757;
    cursor: pointer;

    &:hover {
      color: $netfone-primary;
      text-decoration: underline;
    }
  }
}

@include px_1200 {
  .ntfc-tnc-download-document-wrapper {
    span,
    a {
      font-size: 16px;
    }
  }
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}
.ntfc-selector-dropdown-100 {
  width: 100%;
  height: 40px;

  background: #eeeeee;
  border-radius: 10px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;

  &:focus {
    border: 0;
    outline: unset;
  }
  &.default-dropdown-item-color {
    color: #757575;
  }
  &.padding-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ntfc-selector-option {
  background: #ffffff;
  /* Narancs */
  width: 100%;

  border: 1px solid $netfone-primary;
  box-sizing: border-box;
  /* dropshadow */

  box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 13px;

  button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 197.68%;
    /* or 28px */
    outline: unset;
    border: unset;

    color: $netfone-black;

    &:focus {
      outline: unset;
    }

    &:hover {
      background-color: $netfone-primary;
      color: $netfone-white;
    }
  }
}

.ntfc-input,
.ntfc-input-100 {
  height: 40px;
  background: #eeeeee;
  border-radius: 10px;
  outline: unset;
  border: unset;
  padding-left: 10px;
  padding-right: 10px;
}

.ntfc-input-100 {
  width: 100%;
  height: 40px;
  background: #eeeeee;
  border-radius: 10px;
  outline: unset;
  border: unset;
  padding-left: 10px;
  padding-right: 10px;
  &:disabled {
    cursor: not-allowed;
  }
}

.ntfc-textarea {
  @extend .ntfc-input-100;

  height: unset;

  .no-resize {
    resize: none;
  }
}

.ntfc-form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.ntfc-has-error {
  font-weight: bold !important;
  color: #f75c64 !important;
}

.ntfc-has-success {
  font-weight: bold !important;
  color: #ffc032 !important;
}

.ntfc-form-control-success {
  background: rgba(255, 192, 50, 0.2);
  border: 1px solid #ffc032;
  box-sizing: border-box;
  border-radius: 10px;
  &:focus {
    border: 1px solid #ffc032;
  }
  &.white {
    background: $netfone-white;
  }
}

.ntfc-form-control-danger {
  background: rgba(247, 92, 100, 0.25);
  border: 1px solid #f75c64;
  box-sizing: border-box;
  border-radius: 10px;
  &.white {
    background: $netfone-white;
  }
}

.ntfc-textarea-input {
  width: 100%;
  background: #eeeeee;
  border-radius: 10px;
  outline: unset;
  border: unset;
  padding-left: 10px;
  padding-right: 10px;
}

.ntfc-textarea-success {
  background: rgba(255, 192, 50, 0.2);
  border: 1px solid #ffc032;
  box-sizing: border-box;
  border-radius: 10px;
  &.white {
    background: $netfone-white;
  }
}

.ntfc-textarea-danger {
  background: rgba(247, 92, 100, 0.25);
  border: 1px solid #f75c64;
  box-sizing: border-box;
  border-radius: 10px;
  &.white {
    background: $netfone-white;
  }
}

.profile-picture-wrapper {
  width: 100%;
}

.profile-picture {
  max-height: 390.75px !important;
  max-width: 500px !important;
  width: auto !important;
}

.action-button-wrapper {
  width: 100%;
  justify-content: center;
}

.last-item {
  margin-bottom: 141px !important;
}

.ntfc-modal {
  .modal-content {
    border: 1px solid $netfone-primary;
    border-radius: 12px;
  }
  &.error {
    .modal-content {
      border: 1px solid $netfone-state-error;
    }
  }
}

.ntfc-modal-header {
  background-color: $netfone-primary;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 15px;

  button {
    color: white;

    &:focus {
      outline: unset;
    }
  }

  .modal-title {
    font-weight: bold;
    color: white;
  }

  &.error {
    background-color: $netfone-state-error;
  }
}

.ntfc-modal-body-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 90px;
  background-color: $netfone-background-primary;
}

.ntfc-modal-body-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  min-height: 90px;
  background-color: $netfone-background-primary;
}

.ntfc-modal-footer {
  background-color: $netfone-primary;
  border-radius: 0 0 10px 10px;

  display: flex;
  justify-content: flex-end;
  padding: 15px;

  .ntfc-close,
  .ntfc-confirm {
    button {
      font-size: 16px;
      font-weight: bold;
      border: unset;
      background: unset;

      &:focus {
        outline: unset;
      }
    }
  }

  .ntfc-close {
    button {
      color: $netfone-text-primary;
    }
  }

  .ntfc-confirm {
    button {
      color: white;
    }
  }

  &.error {
    background-color: $netfone-state-error;
  }
}

@media screen and (max-width: 765px) {
  .loader-centered-mobile {
    width: 100vw;
  }
}

.order-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  color: $netfone-black;
  margin-bottom: 14px;
  &.secondary {
    font-size: 14px;
  }
}
.order-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $netfone-black;
}
.order-title-wrapper {
  display: flex;
  flex-direction: column;
}
.form-section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #000000;
}
.form-section-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #000000;
}
.phone-input-wrapper {
  display: flex;
  justify-content: space-between;
}

.unset-background {
  background-color: unset;
}

.ntfc-installment-modal {
  .modal-content {
    width: max-content;
    max-width: 800px;
  }
}

.flex-basis-10 {
  flex-basis: 10%;
}
.flex-basis-20 {
  flex-basis: 20%;
}
.flex-basis-25 {
  flex-basis: 25%;
}
.flex-basis-30 {
  flex-basis: 30%;
}
.flex-basis-40 {
  flex-basis: 40%;
}
.flex-basis-50 {
  flex-basis: 50%;
}
.flex-basis-60 {
  flex-basis: 60%;
}
.flex-basis-70 {
  flex-basis: 70%;
}
.flex-basis-80 {
  flex-basis: 80%;
}
.flex-basis-90 {
  flex-basis: 90%;
}

.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}

@include px_576 {
  .order-title {
    font-size: 20px;
    &.secondary {
      font-size: 18px;
    }
  }
  .order-subtitle {
    font-size: 16px;
  }
}

@include px_1200 {
  .order-title {
    font-size: 24px;
    &.secondary {
      font-size: 22px;
    }
  }
  .order-subtitle {
    font-size: 18px;
  }
}

.no-scroll {
  overflow-y: hidden;
}
.mb-m-40 {
  margin-bottom: -40px;
}

.whitespace-after::after {
  content: '\00a0 ';
}

//.base {
//  transform: translateX(0) !important;
//  transition: transform 1s;
//
//}

.loader-grey-overlay {
  position: fixed;
  width: 100%;
  z-index: 500000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparentize(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

perfect-scrollbar > .ps.ps--active-x > .ps__rail-x {
  opacity: 0.6;
}
perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
  opacity: 0.6;
  background-color: $netfone-white !important;
  border-radius: 9px;
  margin: 10px 0;
  .ps__thumb-y {
    background-color: $netfone-new-orange;
  }
}

perfect-scrollbar > .ps__rail-y {
  width: 20px;
}

.netfone-container {
  width: 100%;
  margin: auto;
}

.ntfc-reseller-gmaps {
  width: 580px;
  height: 630px;
  .map-container {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .ntfc-reseller-gmaps {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
  }
}

@media screen and (max-width: 1200px) {
  .ntfc-reseller-gmaps {
    width: 100%;
  }
}

.pagination {
  justify-content: center;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  background-color: $netfone-new-orange;
  border: none;
  color: $netfone-new-text-primary;
  align-items: center;
  justify-content: center;
  display: flex;
  outline: none;
}

.page-item.active .page-link {
  background-color: $netfone-new-orange;
  color: $netfone-new-text-primary;
  border: none;
  align-items: center;
  justify-content: center;
  display: flex;
  outline: none;
}

.page-link {
  box-shadow: none !important;
}

.page-item.disabled .page-link {
  cursor: not-allowed;
  background: none;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $netfone-new-text-primary;
  //height: $line-height;
  margin: 0 2px;
  min-width: 36px;
  padding: 8px 12px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  display: flex;
}

.new-service-chooser-modal {
  border-radius: 10px;
  .modal-dialog {
    background: $netfone-white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 45px;
    border: 2px solid $netfone-primary;
    max-width: 415px;
    .modal-content {
      border: unset;
      border-radius: 20px;
      height: fit-content;
    }
  }
}

@media screen and (max-width: 768px) {
  .new-service-chooser-modal {
    .modal-dialog {
      max-width: 100%;
      margin: 80px 0 0 0;
      .modal-content {
      }
    }
  }
}

.person-identify-dialog {
  .modal-dialog {
    max-width: 600px;
    height: auto;
    .modal-content {
      padding: 20px;
      border-radius: 20px;
      border: 1px solid $netfone-primary;
    }
  }
}

.share-dialog {
  .modal-dialog {
    max-width: 600px;
    height: auto;
    .modal-content {
      padding: 20px;
      border-radius: 20px;
      border: 1px solid $netfone-primary;
    }
  }
}

// Modal hack
// https://weblog.west-wind.com/posts/2016/sep/14/bootstrap-modal-dialog-showing-under-modal-background

.modal-backdrop {
  z-index: 1040 !important;
}
.modal-content {
  z-index: 1100 !important;
}
